// store/alert_message.js
export const useAlertMessageStore = defineStore('alert_message', {
  state: () => ({
    _visibility: false,
    _content: {},
  }),
  getters: {
    visibility: (state) => state._visibility,
    content: (state) => state._content,
  },
  actions: {
    setVisibility(visibility: boolean) {
      this._visibility = visibility
    },
    setContent(content: object) {
      this._content = content
    },
  },
})
