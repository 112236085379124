<template>
  <div>
    <div class="dropdown dropdown__background dropdown__no-lines">
      <button
        id="dropdownHeaderCompanies"
        class="button button__lines-grey d-flex align-items-center"
        data-bs-toggle="dropdown"
      >
        <span class="mx-auto" style="width: 23px">
          <span style="height: 23px; border-radius: 50%">
            <i class="fas fa-building"></i>
          </span>
        </span>
        <span class="d-none d-md-block ms-2">{{
          selectedClient ? selectedClient.name : "Unknown name"
        }}</span>
      </button>
      <ul
        aria-labelledby="dropdownHeaderCompanies"
        class="dropdown-menu dropdown-menu-end"
      >
        <li>
          <nuxt-link class="dropdown-item" to="/settings/team">
            {{ $t("company.top_menu.team") }}
          </nuxt-link>
        </li>
        <li>
          <nuxt-link class="dropdown-item" to="/settings">
            {{ $t("company.top_menu.settings") }}
          </nuxt-link>
        </li>
        <li v-if="filteredAcceptedCompanies.length > 1">
          <hr class="dropdown-divider" />
        </li>
        <li v-if="filteredAcceptedCompanies.length > 1">
          <div class="dropdown-item-text">
            {{ $t("settings.company.buttons.switch_profile") }}
          </div>
        </li>
        <li
          v-for="acceptedCompany in filteredAcceptedCompanies"
          :key="acceptedCompany.id"
        >
          <div
            class="dropdown-item"
            @click.prevent="selectCompany(acceptedCompany.id)"
            style="cursor: pointer"
          >
            {{ acceptedCompany ? acceptedCompany.name : "Unknown name" }}
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, onBeforeMount, ref, watch } from "vue";
import { useRouter } from "vue-router";
import { clearFulfilmentData } from "~/composables/clearFulfilmentData";
import { useClientsStore } from "~/stores/clients";
import { useUserStore } from "~/stores/user";

const clientsStore = useClientsStore();
const userStore = useUserStore();
const router = useRouter();

const statusModalChangeCompany = ref(false);
const localAcceptedCompanies = ref<any[]>([]);

const selectedClientId = computed(() => clientsStore.selectedClientId);
const selectedClient = computed(() => {
  return clientsStore.selectedClient;
});

const userAcceptedCompanyInvites = computed(() => {
  return userStore.acceptedCompanyInvites;
});

const filteredAcceptedCompanies = computed(() => {
  const filtered = localAcceptedCompanies.value.filter(
    (company) => company.id !== selectedClientId.value
  );
  return filtered;
});

const updateLocalAcceptedCompanies = () => {
  localAcceptedCompanies.value = userStore.acceptedCompanyInvites;
};

watch(
  userAcceptedCompanyInvites,
  (newValue) => {
    if (newValue && newValue.length > 0) {
      updateLocalAcceptedCompanies();
    }
  },
  { immediate: true }
);

onBeforeMount(() => {
  userStore.acceptedCompanies();
});

const selectCompany = async (id: string) => {
  await clearFulfilmentData();
  await clientsStore.updateVisibility(true);
  await clientsStore.setSelectedClient(id);
  await clientsStore.updateVisibility(false);
  await navigateTo("/");
  await window.location.reload();
};

const submit = (msg: string, e: Event) => {
  e.stopPropagation();
};
</script>
